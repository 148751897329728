require("./..\\..\\bower_components\\swiper\\dist\\js\\swiper.js");

module.exports = function () {
	
	$('.reviews__slider').swiper({
		slidesPerView: 4,
		spaceBetween: 26,
		grabCursor: true,
		breakpoints: {
			559: {
				slidesPerView: 1
			},
			767: {
				slidesPerView: 2
			},
			1023: {
				slidesPerView: 3,
				spaceBetween: 0
			},
			1259: {
				slidesPerView: 3,
				spaceBetween: 16
			}
		}
	});

};