'use strict';
var $ = window.jQuery = window.$ = require("./..\\bower_components\\jquery\\dist\\jquery.js");
//require('parsleyjs');
//require('parsleyjs/dist/i18n/ru.js');

var initCustomWidgets = function () {
	require('./modules/clipboard')();
	require('./modules/reviewsInit')();
	require('./modules/paymentsInit')();
	require('./modules/accordeonInit')();
	require('./modules/counters')();
	require('./modules/playVideo')();
	require('./modules/header')();
	require('./modules/popupInit')();
	require('./modules/customSelectInit')();
	require('./modules/extranav')();
    require('./modules/feedback')();
};

$(initCustomWidgets);
