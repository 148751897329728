require("./..\\..\\bower_components\\magnific-popup\\dist\\jquery.magnific-popup.js");

module.exports = function () {

	$('.hamburger').magnificPopup({
		items: {
			src: '#nav',
			type: 'inline'
		},
		removalDelay: 150,
		preloader: false,
		closeBtnInside: false,
		tClose: 'Закрыть (Esc)'
	});

	$('.popup-link').magnificPopup({
		type: 'inline',
		preloader: false,
		closeBtnInside: false,
		removalDelay: 150,
		tClose: 'Закрыть (Esc)'
	});

};