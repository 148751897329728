module.exports = function () {
	
	$(window).on('scroll', function(){
		var $header = $('.header'),
			offset = $(document).scrollTop(),
			windowWidth = $(window).width();

		if ( (offset > 54 && windowWidth >= 768) || (offset > 0 && windowWidth < 768) ) {
			$header.addClass('header_fixed');
		} else {
			$header.removeClass('header_fixed');
		}
	}).scroll();

	$(window).on('resize', function(){
		$(window).scroll();
	});

};
