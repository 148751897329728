module.exports = function () {
	
	$('.extranav__item_active').on('click', function(){
		$('.extranav').toggleClass('extranav_open');
	});

	$(window).on('resize', function(){
		$('.extranav_open').removeClass('extranav_open');
	});

	$(document).on('click', function(e){
		if (!$(e.target).parents('.extranav').length) {
			$('.extranav_open').removeClass('extranav_open');
		}
	});

};
