module.exports = function () {
    $(document.forms.feedback).each(function () {
        var form = this;
        var note = document.getElementsByClassName('feedback__note')[0];
        var messages = {
            initial: note.textContent,
            success: 'Your feedback was successfully posted',
            error: 'An error orrured. Please try again'
        }
        var promise = null;

        $(form)
            .on('submit', function (e) {
                e.preventDefault();

                if (promise == null) {
                    promise = $.ajax({
                        url: form.action,
                        type: form.method,
                        data: $(form).serialize()
                    })
                        .done(function () {
                            form.reset();
                            $(form).removeClass('feedback--error');
                            note.textContent = messages.success;
                        })
                        .fail(function () {
                            $(form).addClass('feedback--error');
                            note.textContent = messages.error;
                        })
                        .always(function () {
                            promise = null;
                        });
                }
            })
            .on('change', function () {
                $(form).filter('.feedback--error')
                       .removeClass('feedback--error');

                if (note.textContent != messages.initial) {
                    note.textContent = messages.initial;
                }
            });
    });
};