module.exports = function () {
	
	counter('#money-paid', 0.21);
	counter('#points-total', 1);
	counter('#points-today', 1);
	counter('#points-month', 1);

	function counter(el, enlarger){
		var $holder = $(el),
			startValue = $holder.text().replace(/\s+/g, '').replace(/,/g, '.'),
			newValue;

		setInterval(function(){

			startValue = startValue * 1 + enlarger;
			newValue = startValue.toLocaleString();
			$holder.text(newValue);

		}, 1000);
	}

};