module.exports = function () {
	
  $('.example__video-link').on('click', function(e){
    var $link = $(this),
		videoId = $link.attr('href').replace('https://youtu.be/','');

	$link.css('display', 'none');
	$link.after('<iframe src="https://www.youtube.com/embed/' + videoId + '?autoplay=1&playsinline=1&showinfo=0&disablekb=1" frameborder="0" allowfullscreen class="example__video-iframe"/>');
	e.preventDefault();
  });

};
