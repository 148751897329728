module.exports = function () {
	
	$('.qlist__toggle').on('click', function(){
		var $group = $(this).parent('.qlist__group'),
			$body = $(this).siblings('.qlist__body'),
			$openGroup = $group.siblings('.qlist__group_open');

		if ($group.is('.qlist__group_open')) {
			$body.slideUp(150, function(){
				$group.removeClass('qlist__group_open');
			});
		} else {
			if ($openGroup.length) {
				$openGroup.children('.qlist__body').slideUp(150, function(){
					$openGroup.removeClass('qlist__group_open');
				});
			}
			$body.slideDown(150);
			$group.addClass('qlist__group_open');
		}
	});

};