require("./..\\..\\bower_components\\swiper\\dist\\js\\swiper.js");

module.exports = function () {

	$('.payments__item').each(function(){
		var $item = $(this),
			$img = $item.find('img'),
			itemWidth = $img.width() + 40;

		$item.css('width', itemWidth);
	});
	
	$('.payments__slider').swiper({
		slidesPerView: 'auto',
		spaceBetween: 30,
		grabCursor: true,
		breakpoints: {
			1023: {
				spaceBetween: 0
			}
		}
	});

};